import { api } from '../../../api/axios-base';
export const handleStudentGroupChange = async (
  studentId,
  previousGroup,
  newGroup,
  reasonChange
) => {
  try {
    console.log(studentId, previousGroup, newGroup, reasonChange);

    if (previousGroup === newGroup) return { ok: true };

    if (previousGroup) {
      const { data: removeData } = await api.post(
        `/app-personas/helpers/desasignar-alumnos `,
        {
          id_alumnos: [studentId],
        }
      );

      if (removeData.e)
        return {
          ok: false,
          error: `No se pudo remover al alumno: ${removeData.e}`,
        };
    }

    const fechaCambio = new Date().toISOString().slice(0, 10);
    const { data: changeData } = await api.post(`/app-personas/cambio-grupo`, {
      alumno: studentId,
      grupo_anterior: previousGroup,
      grupo_nuevo: newGroup,
      fecha_cambio: fechaCambio,
      motivo_cambio: reasonChange,
    });

    if (changeData.e)
      return {
        ok: false,
        error: `No se pudo cambiar al alumno de grupo: ${changeData.e}`,
      };

    return { ok: true, data: { changeData } };
  } catch (error) {
    return { ok: false, error: error.message };
  }
};

export const fetchPostAssignStudentsToGroup = async (
  groupId,
  studyPlanId,
  studentDetails,
) => {
  try {
    const payload = {
      id_grupo: groupId,
      id_plan_estudios: studyPlanId,
      ids_alumnos: studentDetails.map((student) => (
        student
      )),
    };

    const { data } = await api.post(
      `/app-personas/helpers/asignacion-alumnos-a-grupo`,
      payload
    );

    if (data.error) {
      return { ok: false, error: data.error };
    }
    return { ok: true, data };
  } catch (error) {
    return { ok: false, error: error.message };
  }
};

/**
 * Asigna alumnos a un grupo
 * @param {Number} groupId Id de grupo al que se van a asignar los alumnos
 * @param {Number} studyPlanId Id de plan de estudios
 * @param {Array} studentsId Arreglo de ids de los alumnos a asignar
 * @returns Ok true o false
 */
export const postAssignStudentsToGroup = async (
  groupId,
  studyPlanId,
  studentsId = []
) => {
  try {
    const payload = {
      id_grupo: groupId,
      id_plan_estudios: studyPlanId,
      ids_alumnos: studentsId,
    };

    const { data } = await api.post(
      `/app-personas/helpers/asignacion-alumnos-a-grupo`,
      payload
    );

    if (data.error) {
      return { ok: false, error: data.error };
    }
    return { ok: true, data };
  } catch (error) {
    return { ok: false, error: error.message };
  }
};

/**
 * Mueve todos los alumnos de un grupo a otro
 * @param {Number} sourceGroupId Id de grupo origen
 * @param {Number} destinationGroupId Id de grupo destino
 * @returns ok true o false
 */
export const fetchPostTransferStudents = async (
  sourceGroupId,
  destinationGroupId
) => {
  try {
    const { data } = await api.post(`/app-personas/helpers/trasladar-alumnos`, {
      id_grupo_origen: sourceGroupId,
      id_grupo_destino: destinationGroupId,
    });
    if (data.error) {
      return { ok: false, error: data.error };
    }
    return { ok: true, data };
  } catch (error) {
    return { ok: false, error: error.message };
  }
};

/**
 * Mueve algunos alumnos de un grupo a otro
 * @param {Array} studentsId Arreglo de ids de alumnos a mover
 * @param {Number} sourceGroupId Id de grupo destino
 * @param {Number} destinationGroupId Id de grupo destino
 * @param {Number} studyPlanId Id de plan de estudios
 * @returns ok true o false
 */
export const postPromoteStudents = async (
  studentsId = [],
  sourceGroupId,
  destinationGroupId,
  studyPlanId
) => {
  try {
    const { data } = await api.post(`/app-personas/helpers/avanzar-alumnos`, {
      id_alumnos: studentsId,
      id_grupo_origen: sourceGroupId,
      id_grupo_destino: destinationGroupId,
      id_plan_estudios: studyPlanId,
    });
    if (data.error) {
      return { ok: false, error: data.error };
    }
    return { ok: true, data };
  } catch (error) {
    return { ok: false, error: error.message };
  }
};
