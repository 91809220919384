import { handleStudentGroupChange } from "../../../employees/helpers/actionsGroupsOptions";
import KnLocalAlert from "../../../shared/components/KnLocalAlert.vue";
import KnTextArea from "../../../shared/components/KnTextArea.vue";
import { deleteStudent, fetchWithdrawStudent } from "../../helpers/utilsStudent";

export default {
  props: {
    showUnenrollModal: Boolean,
    currentStudent: Object,
  },
  components: {
    KnTextArea,
    KnLocalAlert,
  },
  data() {
    return {
      withdrawalReason: "",
      currentDateTime: '',
      intervalId: null,

      // Alert component
      showAlert: false,
      alertText: "",
      alertType: '',
      alertColor: null,
      loading: false,
      errors: [],

    };
  },
  mounted() {
    const vm = this;
    this.intervalId = setInterval(function () {
      vm.currentDateTime = vm.formatDateTime(new Date())
    }, 1000);
  },
  beforeDestroy() {
    clearInterval(this.intervalId)
  },
  computed: {
    getCurrentDateTime() {
      return this.currentDateTime
    },
    nivelEscolar() {
      return this.currentStudent && this.currentStudent.nivel_educativo
        ? this.currentStudent.nivel_educativo.nombre
        : 'Información no disponible';
    },  
    canProceed() {
      return this.withdrawalReason && this.withdrawalReason.trim() !== '';
    }
  },
  methods: {
    //#region alert methods
    setLoadingState(loading, alertText = '', alertType = 'info') {
      this.loading = loading;
      this.alertText = alertText;
      this.showAlert = loading;
      this.alertType = alertType;
    },

    setErrorState(alertText) {
      this.errors.push(alertText);
      this.loading = false;
      this.alertText = alertText;
      this.alertType = 'error';
      this.showAlert = true;
    },

    setSuccessState(alertText) {
      this.loading = false;
      this.alertText = alertText || this.successAlertText;
      this.alertType = 'success';
      this.alertColor = 'success';
      this.showAlert = true;
    },

    setWarningState(warningText) {
      this.warnings.push(warningText);
      this.alertText = warningText;
      this.alertType = 'warning';
      this.alertColor = 'warning';
      this.showAlert = true;
    },
    //#endregion
    async confirmChange() {
      this.setLoadingState(true, `Buscando datos del alumno`, 'info');
      try {
        const { id: studentId, groupId, institucion_educativa } = this.currentStudent || {};

        if (!studentId || !institucion_educativa?.id) {
          this.setErrorState('Datos del alumno no disponibles. No se pudo proceder con la baja.');
          return;
        }

        if (groupId) {
          this.setLoadingState(true, `Eliminando alumno del grupo`, 'info');
          const { ok } = await handleStudentGroupChange(studentId, groupId, '', 'Baja alumno');

          if (!ok) {
            this.setErrorState('No se pudo eliminar al alumno del grupo.');
            return;
          }
        }

        const formattedDate = new Date().toISOString().split('T')[0];
        const [withdrawResult, deleteResult] = await Promise.all([
          fetchWithdrawStudent({
            alumno: studentId,
            fecha_movimiento: formattedDate,
            institucion_educativa: institucion_educativa.id,
            motivo: this.withdrawalReason,
            tipo_movimiento: 'baja',
          }),
          deleteStudent(studentId)
        ]);

        if (!withdrawResult.ok) {
          this.setErrorState('No se pudo dar de baja al alumno seleccionado.');
        }

        if (!deleteResult.ok) {
          this.setErrorState('No se pudo eliminar el registro del alumno.');
        }

        if (withdrawResult.ok && deleteResult.ok) {
          this.setSuccessState(`Se creó la baja del alumno correctamente.`);
        }

      } catch (error) {
        this.setErrorState('No se pudo dar de baja al alumno seleccionado, por favor intentelo nuevamente.');
        console.error(error);
      }
    },


    actionAlertBtn1() {
      const targetRouteName = 'Alumnos';
      if (this.$route.name === targetRouteName) {
        this.$router.go(0);
      } else {
        this.$router.push({ name: targetRouteName });
      }
      this.showAlert = false;
    },
    action1() {
      this.$emit('actionClose')
    },
    formatDateTime(date) {
      const options = {
        year: 'numeric',
        month: '2-digit',
        day: '2-digit',
        hour: '2-digit',
        minute: '2-digit',
        second: '2-digit'
      }
      return date.toLocaleString('es-ES', options)
    },
    formatCurrency(value) {
      if (!value) return 'N/A'; 
      return new Intl.NumberFormat('es-MX', { style: 'currency', currency: 'MXN' }).format(value);
    },  
    updateDateTime() {
      this.currentDateTime = this.formatDateTime(new Date())
    }
  }
};